// app/src/components/MaintenanceMessage.tsx
import React, { useEffect, useState } from 'react';
import styles from './MaintenanceMessage.module.css';
import { useLocales } from '../locales';

const ALLOWED_IPS: string[] = ['82.193.105.391', '46.30.166.2091'];

const MaintenanceMessage: React.FC = () => {
  const [showMessage, setShowMessage] = useState(false);
  const { translate } = useLocales();

  useEffect(() => {
    const fetchUserIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        if (!ALLOWED_IPS.includes(data.ip)) {
          setShowMessage(true);
        }
      } catch (error) {
        console.error('Error fetching IP address:', error);
        // In case of an error, you might choose to show the maintenance message
        setShowMessage(true);
      }
    };
    +fetchUserIP();
  }, []);

  if (!showMessage) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.messageBox}>
        <h1>{translate('maintenanceMessage.title')}</h1>
        <p>{translate('maintenanceMessage.message')}</p>
      </div>
    </div>
  );
};

export default MaintenanceMessage;
