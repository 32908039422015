import 'simplebar-react/dist/simplebar.min.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@/redux/store';
import createEmotionCache from '@/utils/createEmotionCache';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { safeAreaInsetBottomAtom, safeAreaInsetTopAtom } from '../atoms/_app.states';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { SettingsProvider } from '@/components/settings/SettingsContext';
import ThemeProvider from '../theme';
import './_app.css';
import BaseTracker from '@/components/BaseTracker';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import NoInternetConnection from '@/components/loading-screen/noInternetConnection';
import { CurrencyProvider } from '@/providers/CurrencyProvider';
import Seo from '@/components/seo/Seo';

import { ThemeSettings } from '../components/settings';
import { MotionLazyContainer } from '../components/animate';
import { SnackbarProvider } from '../components/snackbar';
import GlobalSnackbar from '@/components/snackbar/GlobalSnackbar';
import ProgressBar from '../components/progress-bar';
import { MousePositionProvider } from '@/components/animate/MousePositionContext';
import { ApiProvider } from '@/providers/ApiProvider';
import { AuthProvider } from '@/providers/AuthProvider';
import { LoadingBackdropProvider } from '@/utils/LoadingBackdropContext';
import FullscreenInfoMessage from '@/components/fullscreenInfoMessage/FullscreenInfoMessage';
import FullscreenConfirmMessage from '@/components/fullscreenInfoMessage/FullscreenConfirmMessage';
import FullScreenRestartMessage from '@/components/fullscreenInfoMessage/FullScreenRestartMessage';
import MaintenanceMessage from '@/components/MaintenanceMessage';

const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props: { Component: any; pageProps: any; emotionCache?: EmotionCache | undefined }) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  const getLayout = Component.getLayout ?? ((page: any) => page);
  const [safeAreaInsetTop, setSafeAreaInsetTop] = useAtom(safeAreaInsetTopAtom);
  const [safeAreaInsetBottom, setSafeAreaInsetBottom] = useAtom(safeAreaInsetBottomAtom);

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      const updateSafeAreaInsets = () => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
          setSafeAreaInsetTop(insets.top);
          setSafeAreaInsetBottom(insets.bottom);
        });
      };

      updateSafeAreaInsets(); // Initial call

      window.addEventListener('resize', updateSafeAreaInsets);
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          updateSafeAreaInsets();
        }
      });

      return () => {
        window.removeEventListener('resize', updateSafeAreaInsets);
        document.removeEventListener('visibilitychange', updateSafeAreaInsets);
      };
    }
  }, []);

  const [isOnline, setIsOnline] = useState(true);
  const [hasCheckedNetwork, setHasCheckedNetwork] = useState(false);

  useEffect(() => {
    const checkNetworkStatus = async () => {
      const status = await Network.getStatus();
      setIsOnline(status.connectionType !== 'none');
      setHasCheckedNetwork(true);
    };

    const handleNetworkChange = (status: any) => {
      setIsOnline(status.connectionType !== 'none');
    };

    checkNetworkStatus();
    Network.addListener('networkStatusChange', handleNetworkChange);

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <ReduxProvider store={store}>
        <ApiProvider>
          <AuthProvider>
            <SettingsProvider>
              <ThemeProvider>
                <ThemeSettings>
                  <MotionLazyContainer>
                    <SnackbarProvider>
                      <Seo title="AI Portrait Studio" description="Create stunning AI-generated portraits and professional headshots" />
                      <BaseTracker ids={['AW-1031623608', 'G-30WZ6JG0CV']} />
                      <GlobalSnackbar />
                      <ProgressBar />
                      <LoadingBackdropProvider>
                        <CurrencyProvider>
                          <MaintenanceMessage />
                          {/* <MousePositionProvider>{getLayout(<Component {...pageProps} />)}</MousePositionProvider> */}
                          {hasCheckedNetwork && !isOnline && <NoInternetConnection />}
                        </CurrencyProvider>
                      </LoadingBackdropProvider>
                      <FullscreenInfoMessage />
                      <FullscreenConfirmMessage />
                      <FullScreenRestartMessage />
                    </SnackbarProvider>
                  </MotionLazyContainer>
                </ThemeSettings>
              </ThemeProvider>
            </SettingsProvider>
          </AuthProvider>
        </ApiProvider>
      </ReduxProvider>
    </CacheProvider>
  );
}
